.backsideofcenterpace{
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    height: 100%;
    width: 100%;
    opacity: 0;
    transition: .5s ease;
    background-color: white;
    z-index: 99;
    
  }
  .backsideofcenterpace{
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    height: 100%;
    width: 100%;
    opacity: 1;
    transition: .5s ease;
    background-color: white;
    z-index: 99;
    
  }
  .backsideofcenterpace-text{
    position: absolute;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 100%;
    width: 100%;
  }
  .dots {
    width: 17.3px;
    height: 17.3px;
    background: #055779;
    color: #055779;
    border-radius: 50%;
    box-shadow: 28.8px 0, -28.8px 0;
    animation: dots-u8fzftlg 1.2s infinite linear alternate;
  }
  @keyframes dots-u8fzftlg {
    0% {
      box-shadow: 28.8px 0, -28.8px 0;
      background: 0;
    }
  
    33% {
      box-shadow: 28.8px 0, -28.8px 0 rgba(5, 87, 121, 0.13);
      background: rgba(5, 87, 121, 0.13);
    }
  
    66% {
      box-shadow: 28.8px 0 rgba(5, 87, 121, 0.13), -28.8px 0;
      background: rgba(5, 87, 121, 0.13);
    }
  }

  /* Modal.css */
.modal-overlay {
  position: fixed;
  inset: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: rgba(0, 0, 0, 0.5);
}

.modal-container {
  background-color: white;
  padding: 2rem;
  border-radius: 0.5rem;
  width: 24rem;
  position: relative;
}

.modal-header {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  padding: 1rem;
  background-color: #007bff; /* Primary color */
  color: white;
  border-top-left-radius: 0.5rem;
  border-top-right-radius: 0.5rem;
  text-align: center;
}

.modal-message {
  margin-top: 4rem; /* Adjust based on header height */
  font-size: 1.4375rem; /* 23px */
  font-weight: 600;
  font-family: 'Sans-serif';
  color: #007bff; /* Primary color */
}

.modal-button {
  margin-top: 1rem;
  padding: 0.5rem 1rem;
  border: 1px solid #007bff; /* Primary color */
  background-color: #6c757d; /* Secondary color */
  color: #007bff; /* Primary color */
  font-weight: bold;
  border-radius: 0.375rem;
  cursor: pointer;
}

.modal-button:hover {
  background-color: #007bff; /* Primary color */
  color: white;
}
