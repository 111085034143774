.header-design {
  background-color: #f2f6ff !important;
}

.header-design button {
  box-shadow: none !important;
  text-transform: capitalize;
  font-family: "Poppins-Regular";
  background-color: #4c86f9 !important;
}

.splited-header {
  display: flex;
}

.logo-header {
  width: 150px;
}

.logo-header img {
  width: 100%;
  cursor: pointer;
}

.nav-btns {
  flex-grow: 1;
  display: flex;
  gap: 10px;
  justify-content: center;
}

.nav-btns text {
  padding: 3px 10px;
  font-family: "Poppins-Medium";
  font-size: 14px;
  color: #333333;
  cursor: pointer;
}

.aboutus-box {
  background-image: linear-gradient(
    to bottom right,
    #f7db82,
    rgba(246, 188, 0, 50%)
  );
  padding: 30px;
  border-radius: 6px;
  margin-top: 20px;
  position: relative;
}

.aboutus-box h2,
.aboutus-box p {
  margin: 0px;
}

.aboutus-box h2 {
  font-family: "Poppins-SemiBold";
  color: #303030;
}

.aboutus-box p {
  font-family: "Poppins-Regular";
  margin-top: 8px;
  color: #303030;
}

@media only screen and (min-width: 700px) {
  .splited_3_aboutus {
    display: grid;
    grid-template-columns: 30% 30% 30%;
    gap: 20px;
    margin-top: 20px;
  }
}

.content_Points-aboutus {
  display: grid;
  grid-template-columns: 20% 60%;
  gap: 5%;
  position: relative;
}

@media only screen and (min-width: 900px) {
  .content_Points-aboutus:nth-child(1)::after,
  .content_Points-aboutus:nth-child(2)::after {
    position: absolute;
    content: "";
    width: 2px;
    background-color: #525252;
    height: 100%;
    right: 50px;
  }
}

.abouts-img img {
  width: 100%;
}

.abouts-img {
  display: flex;
  justify-content: center;
  align-items: center;
}

.content_Points-aboutus h3 {
  margin: 0px;
  font-family: "Poppins-SemiBold";
}

.content_Points-aboutus text {
  font-family: "Poppins-SemiBold";
  color: #474747;
  margin-top: 3px;
}

.aboutus-points-box {
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
}

@media only screen and (max-width: 700px) {
  .splited_3_aboutus {
    display: flex;
    flex-direction: column;
    gap: 20px;
    margin-top: 20px;
    justify-content: center;
  }

  .abouts-img img {
    width: 90%;
  }

  .aboutus-box {
    justify-content: center;
  }
}

@media only screen and (max-width: 1023px) {
  .star-img {
    display: none;
  }
}

.splited-header {
  display: flex;
  justify-content: space-between;
}

.star-img img {
  width: 100%;
}

.star-img {
  position: absolute;
  width: 420px;
  top: -5%;
  right: 0%;
}

.titile-div {
  text-align: center;
  margin-top: 10px;
}

.titile-div h3 {
  font-family: "Poppins-SemiBold";
  color: #041d4d;
  margin: 0px;
  font-size: 24px;
}

.titile-div p {
  font-family: "Poppins-Regular";
  color: #717171;
  margin: 3px;
}

.services {
  background-color: #e0ebff;
  padding: 40px 0px;
  margin-top: 15px;
}

.services-boxs-splited {
  display: grid;
  grid-template-columns: 32% 32% 32%;
  grid-column-gap: 2%;
  margin-top: 30px;
}

.box-services-card {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  gap: 10px;
  background-color: #ffffff;
  border-radius: 8px;
  padding: 40px;
  margin: 10px 0px;
  cursor: pointer;
}

.box-services-card h3 {
  font-family: "Poppins-SemiBold";
  color: #303030;
  margin: 0px;
}

.box-services-card p {
  font-family: "Poppins-Regular";
  font-size: 14px;
  color: #797979;
  margin: 0px;
  margin-top: 10px;
}

.box-services-img img {
  width: 100%;
}

.box-services-img {
  width: 20%;
}

.products {
  margin: 66px 0px 15px 0px;
  background-color: #e0ebff;
}

.products-img img {
  width: 100%;
}

.products-img {
  margin-top: 30px;
}

.marqueeborder {
  border-top: 2px dashed blue;
  border-bottom: 2px dashed blue;
  margin-top: 30px;
}

.maequeepartnerimg {
  width: 200px;
  margin-right: 40px;
}

.maequeepartnerimg img {
  width: 100%;
}

.footer {
  background-image: url(../../img/footer/footer_back_img.png);
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
}

.splited_footer {
  display: grid;
  grid-template-columns: 60% 40%;
  padding-top: 60px;
}

.box-1-splited {
  display: grid;
  grid-template-columns: 50% 50%;
}

.footer-logo img {
  width: 100%;
}

.box-1-splited p {
  font-family: "Poppins-Medium";
  color: #fff;
  padding-right: 10px;
  text-align: justify;
}

.splited-contantdetails {
  display: flex;
  gap: 10px;
  color: #fff;
  font-family: "Poppins-Regular";
  font-size: 13px;
}

.splited-contantdetails div:nth-child(2) {
  padding-right: 10px;
}

.copyrights-part {
  padding: 10px 0px;
  margin-top: 20px;
  font-family: "Poppins-Regular";
  font-size: 13px;
  color: #ffff;
}

.box_2_splited h2 {
  font-family: "Poppins-SemiBold";
  color: #fff;
  margin: 0px;
}

.box_2_splited ul {
  margin: 0px;
  margin-top: 5px;
}

.box_2_splited {
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  padding: 15px;
}

.box_2_splited ul li {
  font-family: "Poppins-Regular";
  color: #ffff;
  font-size: 14px;
  margin-top: 10px;
  text-decoration: underline;
  cursor: pointer;
}

.footer-icons {
  display: flex;
  color: #fff;
  gap: 5px;
  align-items: center;
  margin: 0;
 
}

.footer-icon-img {
  width: 30px;
}

.footer-icon-img img {
  width: 100%;
}

.fotter-textfield label {
  padding: 10px 0px;
  padding-top: 0px;
  font-size: 16px;
  font-family: "Poppins-Medium";
}

.fotter-textfield label text {
  color: #e40808f8;
}

.fotter-textfield input::placeholder {
  font-size: small;
  font-family: "Poppins-Regular";
}

.fotter-textfield {
  margin-top: 10px;
}

.form {
  background-color: #fff;
  padding: 20px;
  border-radius: 10px;
}

.form h3 {
  margin: 0px;
  margin-bottom: 20px;
  color: #0e2f71;
  font-family: "Poppins-SemiBold";
  font-size: larger;
}

/* Media Queries for Responsive Design */
@media (max-width: 768px) {
  .splited_footer {
    grid-template-columns: 1fr;
    padding-top: 30px;
  }

  .box-1-splited {
    grid-template-columns: 1fr;
  }

  .box_2_splited {
    padding: 10px;
  }

  .footer-icons {
    justify-content: center;
    padding-left: 0;
    margin-top: 0px;
  }

  .form {
    margin-top: 20px;
    padding: 15px;
  }

  .footer-logo img {
    width: 80%;
    margin: 0 auto;
  }

  .splited-contantdetails {
    display: flex;
    flex-direction: row;
    row-gap: 5px;
  }

  .splited-contantdetails div:nth-child(2) {
    padding-right: 0;
  }
}

.aboutus-img-content {
  margin-top: 15px;
  background-image: url(../../img/aboutus-img/background-img.png);
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  padding: 30px 0px;
}

.splited-aboutus-content {
  display: grid;
  grid-template-columns: 50% 50%;
}

@media (max-width: 600px) {
  .splited-aboutus-content {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
}

.rightsideimg-aboutus img {
  width: 100%;
}

.splited-img-icon {
  display: flex;
  gap: 10px;
  align-items: center;
}

.img-icon-mission img {
  width: 100%;
}

.img-icon-mission {
  width: 30px;
}

.splited-img-icon text {
  font-family: "Poppins-Medium";
  font-size: 22px;
  color: #0c3d9f;
}

.mission p,
.Vision p {
  font-family: "Poppins-Regular";
  font-size: 18px;
  color: #323232;
}

.content-splited {
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
}

/* ============== product-img ============= */
.banner-img {
  background-color: #00163e;
  position: relative;
}

@media only screen and (max-width: 700px) {
  .banner-img {
    margin-top: 50px;
  }

  .connet-box-banner {
    padding: 10px 20px !important;
  }

  .contentbox-banner p {
    margin-bottom: 5px !important;
    font-size: 8px !important;
  }

  .spliteded-products {
    grid-template-columns: 100% !important;
  }

  .sideimg-top,
  .sideimg-top-2 img {
    display: none;
  }

  .banner-img h1 {
    font-size: 12px;
  }

  .banner-img button {
    font-size: 8px;
  }
}

.products-mobileview img {
  width: 100%;
}

.banner-img img {
  width: 70%;
}

.contentbox-banner {
  position: absolute;
  bottom: 0%;
  left: 50%;
  background-color: white;
  transform: translate(-50%, 0%);
  text-align: center;
  border-radius: 5px 5px 0px 0px;
}

.contentbox-banner h1 {
  font-family: "Poppins-SemiBold";
  color: #303030;
}

.contentbox-banner p {
  font-family: "Poppins-Regular";
  margin-bottom: 25px;
  color: #3d3d3d;
}

.connet-box-banner {
  text-align: center;
  position: relative;
  padding: 10px 40px;
  padding-bottom: 20px;
}

.sideimg-top {
  width: 120px;
  position: absolute;
  top: -17%;
  right: -6%;
}

.sideimg-top-2 {
  width: 100px;
  position: absolute;
  top: -17%;
  left: -6%;
}

.sideimg-top img {
  width: 100%;
}

.sideimg-top-2 img {
  width: 100%;
}

.spliteded-products {
  display: grid;
  grid-template-columns: 48% 48%;
  column-gap: 4%;
  row-gap: 40px;
}

.sideimg-productus-details {
  width: 80%;
}

.sideimg-productus-details img {
  width: 100%;
}

.center-the-img-for-products {
  display: flex;
  justify-content: center;
  align-items: center;
}

.spliteded-products h2 {
  font-family: "Poppins-Semibold";
  color: #0c3d9f;
}

.spliteded-products p {
  margin: 0px;
  margin-top: 5px;
  font-family: "Poppins-Regular";
  color: #7f7f7f;
}

.contactUsCard {
  border: 1px solid #c0e5ff;
  padding: 3rem;
  box-shadow: 0 4px 8px rgba(192, 229, 255);
  margin-bottom: 1rem;
  border-radius: 10px;
}

.contactUsIcon {
  background-color: #bce3ff;
  border-radius: 50%;
  padding: 1rem;
  margin-top: 5px;
}

/* =====================servicespage =============== */

.bannerconnet-services {
  padding: 50px 0;
  display: flex;
  justify-content: center;
  background-image: url(../../img/servicepage-img/web-devel-important\ 1.png);
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
}

.servicescenter-conent p {
  margin-bottom: 30px;
  font-family: "Poppins-Regular";
  font-size: 14px;
  font-weight: 400;
}

.servicescenter-conent button {
  font-family: "Poppins-Regular";
  text-transform: capitalize;
}

.Our-Offered-Services {
  padding-top: 40px;
  background-color: #efffdb;
  padding-bottom: 25px;
}

.icon-splited {
  color: #4c86f9;
  padding: 15px;
  background-color: #d4edff;
  border: 1px solid #2ea7ff;
  border-radius: 50%;
}

.splited-the-services-area div {
  text-align: center;
}

.splited-the-services-area h3 {
  font-family: "Poppins-Semibold";
  color: #041d4d;
}

.splited-the-services-area p {
  font-family: "Poppins-Regular";
  color: #6f6f6f;
  font-size: 15px;
}

.Technologies {
  padding: 20px 0px;
  background-color: #e3edff;
  margin-top: 30px;
}

.Technologies p {
  font-family: "Poppins-SemiBold";
  text-align: center;
  font-size: 19px;
  color: #041d4d;
}

.Technologies p text {
  color: #53890e;
}

.technologies-img {
  display: flex;
  justify-content: center;
  gap: 20px;
}

.technologies-img img {
  width: 50%;
}

.technologies-img div {
  display: flex;
  justify-content: center;
  align-items: center;
}

/* Extra small devices (phones, 600px and down) */
@media only screen and (max-width: 300px) {
  .services-boxs-splited {
    display: grid;
    grid-template-columns: 100%;
    grid-column-gap: 2%;
    margin-top: 30px;
  }
  .servicescenter-conent h1 {
    font-family: "Poppins-SemiBold";
    font-size: 24px;
  }
  .servicescenter-conent {
    width: 80%;
    text-align: center;
    color: #ffffff;
    padding: 20px 0;
  }
  .splited-the-services-area {
    display: grid;
    grid-template-columns: 100%;
    gap: 31px;
    margin-top: 30px;
  }
  .footer-logo {
    display: flex;
    justify-content: center;
    width: 100%;
  }
  .box_2_splited {
    text-align: center;
  }
  .footerUlList {
    list-style-type: none;
    margin: 0;
    padding: 0;
  }
  .footerUlList li {
    margin: 0;
    padding: 0;
  }
}

@media only screen and (min-width: 300px) {
  .services-boxs-splited {
    display: grid;
    grid-template-columns: 100%;
    grid-column-gap: 2%;
    margin-top: 30px;
  }
  .servicescenter-conent h1 {
    font-family: "Poppins-SemiBold";
    font-size: 24px;
  }
  .servicescenter-conent {
    width: 80%;
    text-align: center;
    color: #ffffff;
    padding: 20px 0;
  }

  .splited-the-services-area {
    display: grid;
    grid-template-columns: 100%;
    gap: 31px;
    margin-top: 30px;
  }
  .footer-logo {
    display: flex;
    justify-content: center;
    width: 100%;
  }
  .box_2_splited {
    text-align: center;
  }
  .footerUlList {
    list-style-type: none;
    margin: 0;
    padding: 0;
  }
  .footerUlList li {
    margin: 0;
    padding: 0;
  }
}

@media only screen and (min-width: 350px) {
}

@media only screen and (min-width: 400px) {
}
/* Small devices (portrait tablets and large phones, 600px and up) */
@media only screen and (min-width: 600px) {
  .services-boxs-splited {
    display: grid;
    grid-template-columns: 50% 50%;
    grid-column-gap: 2%;
    margin-top: 30px;
  }
}

/* Medium devices (landscape tablets, 768px and up) */
@media only screen and (min-width: 768px) {
  .servicescenter-conent h1 {
    font-family: "Poppins-SemiBold";
    font-size: 38px;
  }
  .servicescenter-conent {
    width: 50%;
    text-align: center;
    color: #ffffff;
    padding: 20px 0;
  }
  .splited-the-services-area {
    display: grid;
    grid-template-columns: 50% 50%;
    gap: 31px;
    margin-top: 30px;
  }
  .form {
    margin-top: 40px;
    padding: 15px;
  }
}

/* Large devices (laptops/desktops, 992px and up) */
@media only screen and (min-width: 992px) {
  .box_2_splited {
    text-align: left;
  }
  .services-boxs-splited {
    display: grid;
    grid-template-columns: 32% 32% 32%;
    grid-column-gap: 2%;
    margin-top: 30px;
  }
  .splited-the-services-area {
    display: grid;
    grid-template-columns: 23% 23% 23% 23%;
    gap: 31px;
    margin-top: 30px;
  }
}

/* Extra large devices (large laptops and desktops, 1200px and up) */
@media only screen and (min-width: 1200px) {
}
/* .css-1oqqzyl-MuiContainer-root {
  max-width: 100% !important;
  width: 100% !important;
  min-width: 100% !important;
  padding: 0 !important;
  margin: 0 !important;
}

@media (min-width: 1200px) {
  .css-1oqqzyl-MuiContainer-root {
    max-width: 100% !important;
    padding: 0 !important;
    margin: 0 !important;
  }
}

@media (min-width: 600px) {
  .css-1oqqzyl-MuiContainer-root {
    padding: 0 !important;
  }
} */
